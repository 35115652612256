import React from "react";

const NotFound = () => {

    return (
      <div>
        This page could not be found
      </div>
    )
}

export default NotFound